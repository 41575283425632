import React from "react";
import {MDXProvider} from "@mdx-js/react";
import { lazy } from "@loadable/component";
import GlobalStyles from './../assets/styles/GlobalStyles';
import Loader from '../components/Loader';


const ScreenerControl = lazy(() => import("./Screener/ScreenerControl"));
const FAQSHeroComponent = lazy(() => import('../components/FAQs/Hero'));
const BubbleHero = lazy(() => import('../components/BubbleHero'));
const Typography = lazy(() => import('../components/Typography'));
const Section = lazy(() => import('../components/Section'));
const TwoColumns = lazy(() => import('../components/TwoColumns'));
const Accordion = lazy(() => import('../components/Accordion'));
const FooterBubbles = lazy(() => import('../components/FooterBubbles'));
const CardSlider = lazy(() => import('../components/CardSlider'));
const Card = lazy(() => import('../components/Card'));
const SiteShare = lazy(() => import('../components/SiteShare'));
const ClinicalTrialsSlider = lazy(() => import('../components/AboutClinicalTrials/ClinicalTrialsSlider'));
const AboutCOPDHeader = lazy(() => import('../components/AboutCOPD/AboutCOPDHeader'));
const TypesOfTrial = lazy(() => import('../components/AboutClinicalTrials/TypesOfTrial'));
const SingleColumnText = lazy(() => import('../components/AboutClinicalTrials/SingleColumnText'));
const HowDoAirwaysWork = lazy(() => import('../components/AboutCOPD/HowDoAirwaysWork'));
const MoreAboutCOPD = lazy(() => import('../components/AboutCOPD/MoreAboutCOPD'));
const References = lazy(() => import('../components/AboutCOPD/References'));
const VideoComponent = lazy(() => import('../components/AboutCOPD/VideoComponent'));
const WhyWeNeedCOPDTreatments = lazy(() => import('../components/AboutCOPD/WhyWeNeedCOPDTreatments'));
const TypicalPhases = lazy(() => import('../components/AboutClinicalTrials/TypicalPhases'));
const TextBubbleWithImage = lazy(() => import('../components/AboutTheTrial/TextBubbleWithImage'));
const TextWithBubbleGroups = lazy(() => import('../components/AboutTheTrial/TextWithBubbleGroups'));
const AboutTheTrialFAQs = lazy(() => import('../components/AboutTheTrial/AboutTheTrialFAQs'));
const TextWithCards = lazy(() => import('../components/AboutTheTrial/TextWithCards'));
const CarouselWithText = lazy(() => import('../components/AboutTheTrial/CarouselWithText'));
const CarouselInContainer = lazy(() => import('../components/CarouselInContainer'));
const TrialInformation = lazy(() => import('../components/AboutTheTrial/TrialInformation'));
const CommonQuestions = lazy(() => import('../components/AboutTheTrial/CommonQuestions'));
const SafetySlider = lazy(() => import('../components/AboutClinicalTrials/SafetySlider'));
const WrapComponent = lazy(() => import('../components/WrapComponent'));
const Process = lazy(() => import('../components/Process'));
const FAQs = lazy(() => import('../components/FAQs/FAQs'));
const FAQComponent = lazy(() => import('../components/FAQs/FAQComponent'));
const FlaringConfirmation = lazy(() => import('../components/FlaringConfirmation'));
const TwoColumnsPageWithImage = lazy(() => import('../components/TwoColumnsPageWithImage'));
const OverlayModal = lazy(() => import('../components/OverlayModal'));
const NotFound = lazy(() => import('../components/NotFound'));
const CountrySelector = lazy(() => import('../components/CountrySelector'));
const PrivacyPolicy = lazy(() => import('../components/PrivacyPolicy'));
const Unsubscribe = lazy(() => import('../components/Unsubscribe'));
const FooterController = lazy(() => import('./Footer.jsx'));
const Header = lazy(() => import('./Header'));
const AboutClinicalTrial = lazy(() => import('./Homepage/AboutClinicalTrials'));
const BulletListWithImage = lazy(() => import('./Homepage/BulletListWithImage'));
const JoinSimpleSteps = lazy(() => import('./Homepage/JoinSimpleSteps'));
const TextComponentTwoColumns = lazy(() => import('./Homepage/TextComponentTwoColumns'));
const TextWithImage = lazy(() => import('./Homepage/TextWithImage'));
const TextBlock = lazy(() => import('./Homepage/TextBlock'));
const BulletList = lazy(() => import('./FeaturedTrial/BulletList'));
const PhaseSlider = lazy(() => import("./AboutClinicalTrials/PhaseSlider"));
const FullScreenMedia = lazy(() => import("./FullScreenMedia"));
const ReferenceNote = lazy(() => import('../components/AboutTheTrial/ReferenceNote'));
const OurTrialsIntro = lazy(() => import("./OurTrials/OurTrialsIntro"));
const Timeline = lazy(() => import("./HowItWorks/Timeline"));
const ExpandingContent = lazy(() => import("./HowItWorks/ExpandingContent"));
const InteractiveTextQuadrant = lazy(() => import("./HowItWorks/InteractiveTextQuadrant"));
const OurTrialsTable = lazy(() => import("./OurTrials/OurTrialsTable"));
const CTABanner = lazy(() => import("./CTABanner"));
const LiveSupport = lazy(() => import("./LiveSupport"));
const DownloadGuide = lazy(() => import('../components/ClinicalTrial/DownloadGuide'));
const CopyLink = lazy(() => import('../components/CopyLink'));

const wrapRootElement = ({children}) => {

  return(
    <>
      <GlobalStyles/>
      <MDXProvider components={{
        AboutClinicalTrial: AboutClinicalTrial,
        AboutTheTrialFAQs: AboutTheTrialFAQs,
        AboutCOPDHeader: AboutCOPDHeader,
        BulletListWithImage: BulletListWithImage,
        BulletList: BulletList,
        ClinicalTrialsSlider: ClinicalTrialsSlider,
        CountrySelector: CountrySelector,
        FAQs: FAQs,
        FAQSHeroComponent: FAQSHeroComponent,
        BubbleHero: BubbleHero,
        Typography: Typography,
        Section: Section,
        TwoColumns: TwoColumns,
        Accordion: Accordion,
        AccordionItem: Accordion.Item,
        FooterBubbles: FooterBubbles,
        CardSlider: CardSlider,
        Card: Card,
        SiteShare: SiteShare,
        FAQComponent: FAQComponent,
        FlaringConfirmation: FlaringConfirmation,
        FooterController: FooterController,
        FullScreenMedia: FullScreenMedia,
        Header: Header,
        HowDoAirwaysWork: HowDoAirwaysWork,
        JoinSimpleSteps: JoinSimpleSteps,
        Loader: Loader,
        MoreAboutCOPD : MoreAboutCOPD,
        NotFound : NotFound,
        PhaseSlider : PhaseSlider,
        References : References,
        SafetySlider : SafetySlider,
        ScreenerControl: ScreenerControl,
        SingleColumnText : SingleColumnText,
        TextBubbleWithImage: TextBubbleWithImage,
        TextComponentTwoColumns: TextComponentTwoColumns,
        TextWithImage: TextWithImage,
        TextBlock: TextBlock,
        TextWithBubbleGroups: TextWithBubbleGroups,
        TextWithCards: TextWithCards,
        CarouselWithText: CarouselWithText,
        CarouselInContainer: CarouselInContainer,
        TwoColumnsPageWithImage : TwoColumnsPageWithImage,
        OverlayModal: OverlayModal,
        TrialInformation: TrialInformation,
        CommonQuestions: CommonQuestions,
        TypesOfTrial : TypesOfTrial,
        TypicalPhases : TypicalPhases,
        Unsubscribe: Unsubscribe,
        VideoComponent : VideoComponent,
        WhyWeNeedCOPDTreatments : WhyWeNeedCOPDTreatments,
        WrapComponent : WrapComponent,
        Process: Process,
        PrivacyPolicy : PrivacyPolicy,
        ReferenceNote : ReferenceNote,
        OurTrialsIntro: OurTrialsIntro,
        OurTrialsTable: OurTrialsTable,
        Timeline: Timeline,
        ExpandingContent: ExpandingContent,
        InteractiveTextQuadrant: InteractiveTextQuadrant,
        CTABanner: CTABanner,
        LiveSupport: LiveSupport,
        DownloadGuide: DownloadGuide,
        CopyLink: CopyLink
      }}>
        <div>
          {children}
        </div>
      </MDXProvider>
    </>
)};

export default wrapRootElement;
