import {createGlobalStyle} from 'styled-components';
import './fonts.css';

export const colors = {
    primary: '#C2D7F4',
    primaryAlternate: '#140D4F',
    secondary: '#FF5500',
    secondaryAlternate: '#FFE9D7',
    lightSand: '#FFF7F1',
    darkSand: '#FFDDC2',


    //Not Changed May Still be in use
    light: '#F6F9F9',
    dark: 'rgb(57,57,57)',
    white: 'white',
    black: 'black',
    grey: '#808080',
    sand: '#F5EDE3',
    sandAlt: '#FEF8F2',
    greyAlt: '#EFEDED'
}

export const mediaQueriesSizes = {
    desktopNav:   1260,
    xxxl:         1900,
    xxl:         1600,
    xl:         1169,
    lg:         900,
    md:         700,
    sm:         500,
};
export const containerWidths = {
    xxl:        1560,
    xl:         1139,
    lg:         870,
    md:         670,
    sm:         470,
};

const GlobalStyles = createGlobalStyle`

    html {
        overflow-x: hidden;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
        scroll-behavior: smooth;

        &.scrolling-lock {
            overflow: hidden;
        }        
    }

    .divider-secondary{
        hr{
            background: ${colors.secondary}; 
        }
    }

    #CookiebotWidget {
        box-shadow: none !important;
    }

    .CookiebotWidget-logo {
        background: url('/static-images/Cookie-icon.svg') center no-repeat !important;
        background-size: 40px 40px !important;
        background-color: white !important;
        border-radius: 40px;
        svg {
            opacity: 0 !important;
            width: 44px !important;
            height: 44px !important;
        }
    }
    
    body {
        font-family: 'PolySans';
        font-size: 16px;
        line-height: 1.2;
        background-color: ${colors.white};
        margin:0;
        -webkit-font-smoothing: antialiased; /* Chrome, Safari */
        -moz-osx-font-smoothing: grayscale; /* Firefox */
        font-weight:300;
        position:relative;
        overflow-x: hidden;

        &.scrolling-lock {
            overflow: hidden;
        }
    }
    
    h1,h2,h3,h4,h5,h6,p{
        margin:0;
        font-weight:300;
    }
    
    h1{
        font-size:36px;
        line-height:1;
    }
    
    h2{
        font-size: 36px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 0em;
    }
    
    h4{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.1875;
    }
    
    h5{
        font-size:16px;
        line-height: 1.188;
    }

    sup{
        line-height:0;
    }

    .reference{
    
    }

    select {
        font-family: 'PolySans';
    }
  
    * {
        box-sizing: border-box;
    }

    .split-colour-bg {
        background: linear-gradient(
            to bottom,
            ${colors.primary} 0%,
            ${colors.primary} 100vh,
            ${colors.sand} 100vh,
            ${colors.sand} 100%
        );
    }
    
    #CookiebotWidget {
        @media (max-width: ${mediaQueriesSizes.sm}px) {
            bottom: 90px !important;
       }
    }
    
    .container{
        width:100%;
        padding:0 20px;
        margin:0 auto;
        
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            padding:0 15px;
            width:${containerWidths.sm}px;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width:${containerWidths.md}px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width:${containerWidths.lg}px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width:${containerWidths.xl}px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            width:${containerWidths.xxl}px;
        }
        
        
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            width:${containerWidths.sm}px;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width:${containerWidths.md}px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width:${containerWidths.lg}px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width:${containerWidths.xl}px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            width:${containerWidths.xxl}px;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    .skip-link {
        height: 1px;
        left:-999px;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 1px;
        z-index:-999;

        &:focus,
        &:active {
            background-color: ${colors.dark};
            color: ${colors.white};
            left: 0;
            width: auto;
            height: auto;
            overflow: auto;
            margin: 10px;
            padding: 5px;
            text-align:center;
            z-index:999;
        }
    }

    .tooltip-theme {
        &.tippy-box {
            background-color: ${colors.sand};
            color: ${colors.black};
            pointer-events: all;

            .tippy-arrow {
                background-color: ${colors.sand};
                color: ${colors.sand}; 
            }

            .tippy-content {
                padding: 60px;
            }

            .tippy-close {
                background: transparent;
                border: 0;
                cursor: pointer;
                height: 17px;
                position: absolute;
                top: 20px;
                right: 20px;
                width: 17px;

                img {
                    width: 17px;
                    height: 17px;
                }
            }

            h4 {
                color: ${colors.black};
                font-weight: 700;
                font-size: 22px;
                line-height: 1.64;
            }

            p {
                color: ${colors.black};
                font-weight: 300;
                font-size: 16px;
                line-height: 1.62;
                margin: 18px 0 0;

                a {
                    color: ${colors.black};
                    text-decoration: underline;
                }
            }
        }
        &.tooltip-theme-dark.tippy-box{
            background-color: ${colors.primary};
            color: ${colors.black};

            .tippy-arrow {
                background-color: ${colors.primary};
                color: ${colors.primary}; 
            }            
        }
        &.tooltip-theme-small-padding.tippy-box{
            .tippy-content {
                padding: 27px 25px 24px;

                @media (min-width: ${mediaQueriesSizes.md}px) {
                    padding: 36px 92px 30px 40px;
                }

                .tippy-close {
                    height: 10px;
                    top: 13px;
                    right: 14px;
                    width: 10px;

                    @media (min-width: ${mediaQueriesSizes.md}px) {
                        top: 40px;
                        right: 34px;
                        width: 20px;
                        height: 20px;
                    }

                    img {
                        width: 10px;
                        height: 10px;

                        @media (min-width: ${mediaQueriesSizes.md}px) {
                            width: 20px;
                            height: 20px;
                        }                        
                    }                    
                }

                p {
                    margin: 0;
                }
            }            
        }
    }  

    .overlay-close {
        background: none;
        border: 0;
        position: absolute;
        top: 20px;
        right: 20px;
    }  

    .overlay-close-button {
        width: calc(100% - 40px);
    }
`;

export default GlobalStyles;
