const countries = [
  {
    url: 'en-US',
    title: 'USA English',
    country: ['US'],
    language: ['en-US'],
    img: require('/static/images/global/flags/US.png'),
    alt: 'USA flag linking to our US English website',
    cookieID: 'caf2e429-9b7c-4e2c-ad5e-804849bdb327',
    logo: 'combined'
  },
  {
    url: 'es-US',
    title: 'USA Español',
    country: ['US'],
    language: ['es-US'],
    img: require('/static/images/global/flags/US.png'),
    alt: 'USA flag linking to our US Spanish website',
    cookieID: 'caf2e429-9b7c-4e2c-ad5e-804849bdb327',
  },
  {
     url: 'en-GB',
     title: 'UK English',
     country: ['GB'],
     language: ['en-GB'],
     img: require('/static/images/global/flags/UK.png'),
     alt: 'UK flag linking to our UK English website',
     cookieID: '937398fd-fcbd-4562-a984-b1b01b76efc2',
  },
  {
    url: 'en-CA',
    title: 'Canada English',
    country: ['CA'],
    language: ['en-CA'],
    img: require('/static/images/global/flags/CA.png'),
    alt: 'Canada flag linking to our CA English website',
    cookieID: '95b24fb4-6a40-4f69-bee3-490ba0e6eab0',
  },
  {
    url: 'fr-CA',
    title: 'Canada Français',
    country: ['CA'],
    language: ['fr-CA'],
    img: require('/static/images/global/flags/CA.png'),
    alt: 'Canada flag linking to our Canada French website',
    cookieID: '95b24fb4-6a40-4f69-bee3-490ba0e6eab0',
  },
  {
    url: 'es-MX',
    title: 'Mexico Español',
    country: ['MX'],
    language: ['es-MX'],
    img: require('/static/images/global/flags/MX.png'),
    alt: 'Mexico flag linking to our Mexico Spanish website',
    cookieID: '898f660d-90e5-47f9-b628-d4aaf6309cc4',
  }
];

export default countries;
