/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import CookieIDs from '../../../data/countries';

const CookieBanner = () => {

    const currentCode = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : '';    
    const currentCountry = CookieIDs.filter((country) => {
        return country.url === currentCode;
    });

    function loadGoogleAnalytics() {
        // Google Tag Manager script
        (function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',`${process.env.GATSBY_GTMCODE}`);    

        window.dataLayer = window.dataLayer || [];

        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTMCODE}`;
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';

        noscript.appendChild(iframe);
        const body = document.body;
        body.insertBefore(noscript, body.firstChild);
    }

    function loadHotJar() {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:process.env.GATSBY_HOTJAR_ID,hjsv:process.env.GATSBY_HOTJAR_SV};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');    
    }

    function loadRedditPixel() {
        (function(w,d){
            if(!w.rdt){
                var p=w.rdt=function(){
                    p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments);
                };
                p.callQueue=[];
                var t=d.createElement("script");
                t.src="https://www.redditstatic.com/ads/pixel.js";
                t.async=true;
                var s=d.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(t,s);
            }
        })(window,document);
    
        if (typeof rdt === 'function') {
            rdt('init', 'a2_ewxo28naka7s');
            rdt('track', 'PageVisit');
        } else {
            console.error('rdt is not properly initialized');
        }
    }
    

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('CookiebotOnAccept', () => {
                if (window.CookieConsent && window.CookieConsent.consent) {
                    const consent = window.CookieConsent.consent;
                    if (consent.statistics === true && consent.marketing === true) {
                        loadGoogleAnalytics();
                        loadRedditPixel();
                    }
                    if (consent.statistics === true) {
                        loadHotJar();
                    }
                }
            }, false);
        }
    }, []);

    if (!currentCode) {
        return null;
    }

    return (
        <>
            <Helmet>
                <script 
                    async 
                    id="Cookiebot" 
                    src="https://consent.cookiebot.com/uc.js" 
                    data-cbid={currentCountry && currentCountry.length > 0 ? currentCountry[0].cookieID : 'caf2e429-9b7c-4e2c-ad5e-804849bdb327'} 
                    data-blockingmode="auto" 
                />
            </Helmet>
        </>
    )
}

export default CookieBanner;
